import React from 'react';
import _ from 'lodash';
import ScrollTrigger from 'react-scroll-trigger';
import anime from 'animejs';

import { Layout } from '../components/index';
import { safePrefix, htmlToReact, Link } from '../utils';
import Facebook from '../images/facebook-color.png';
import Instagram from '../images/instagram-color.png';
import Whatsapp from '../images/whatsapp-color.png';

export default class About extends React.Component {
    constructor(props) {
        super(props);
        this.blockOne = React.createRef();
        this.blockTwo = React.createRef();
        this.animationPlayed = [false, false];
    }

    onEnterBlockOne() {
        if (!this.animationPlayed[0]) {
            anime({
                targets: this.blockOne.current,
                translateY: ['50px', '0px'],
                opacity: [0, 1],
                easing: 'easeInOutExpo',
                delay: 500
            })
            this.animationPlayed[0] = true;
        }
    }

    render() {
        return (
            <Layout {...this.props}>
                <div className="about-page-container">
                    <div className="animation-container" ref={this.blockOne}>
                        <div id="brand-story" className="brand-story-container">
                            <div className="brand-story-left-container">
                                <img src={safePrefix(_.get(this.props, 'pageContext.frontmatter.brandStory.img'))} />
                            </div>
                            <div className="brand-story-right-container">
                                <div className="brand-story-title">
                                    {_.get(this.props, 'pageContext.frontmatter.brandStory.title')}
                                </div>
                                <div className="brand-story-content">
                                    {htmlToReact(_.get(this.props, 'pageContext.frontmatter.brandStory.content'))}
                                </div>
                            </div>
                        </div>

                        <div id="brand-principle" className="brand-principle-container">
                            <div className="brand-principle-left-container">
                                <div className="brand-principle-title">
                                    {_.get(this.props, 'pageContext.frontmatter.brandPrinciple.title')}
                                </div>
                                <div className="brand-principle-content">
                                    {
                                        _.get(this.props, 'pageContext.frontmatter.brandPrinciple.principles').map((principle) =>
                                            <div className="brand-principle-item">
                                                <div className="brand-principle-item-title">
                                                    {principle.title}
                                                </div>
                                                <div className="brand-principle-item-content">{principle.content}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="brand-principle-right-container">
                                <img src={safePrefix(_.get(this.props, 'pageContext.frontmatter.brandPrinciple.img'))} />
                            </div>
                        </div>
                    </div>
                    <div id="contact-us" className="contact-us-container" ref={this.blockTwo}>
                        <div className="contact-us-title">
                            {_.get(this.props, 'pageContext.frontmatter.contact.title')}
                        </div>
                        <div className="contact-us-card-container">
                            <div className="contact-us-phone">
                                <div className="contact-us-title">
                                    {_.get(this.props, 'pageContext.frontmatter.contact.phone.title')}
                                </div>
                                <div className="contact-us-card-border" />
                                <div className="contact-us-content">
                                    {htmlToReact(_.get(this.props, 'pageContext.frontmatter.contact.phone.content'))}
                                    <div className="contact-us-btn-wrapper">
                                        <Link target="_blank" to={_.get(this.props, 'pageContext.frontmatter.contact.phone.btnUrl')}>
                                            <button className="contact-us-button green-btn">{_.get(this.props, 'pageContext.frontmatter.contact.phone.btnLabel')}</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-us-socialMedia">
                                <div className="contact-us-title">
                                    {_.get(this.props, 'pageContext.frontmatter.contact.socialMedia.title')}
                                </div>
                                <div className="contact-us-card-border" />
                                <div className="contact-us-content">
                                    <div>{htmlToReact(_.get(this.props, 'pageContext.frontmatter.contact.socialMedia.content'))}</div>
                                    <div className="social-media">
                                        <Link target="_blank" to={_.get(this.props, 'pageContext.site.siteMetadata.footer.socialMedia.facebook')}>
                                            <img src={Facebook} />
                                        </Link>
                                    </div>
                                    <div className="social-media">
                                        <Link target="_blank" to={_.get(this.props, 'pageContext.site.siteMetadata.footer.socialMedia.whatsapp')}>
                                            <img src={Whatsapp} />
                                        </Link>
                                    </div>
                                    <div className="social-media">
                                        <Link target="_blank" to={_.get(this.props, 'pageContext.site.siteMetadata.footer.socialMedia.instagram')}>
                                            <img src={Instagram} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
